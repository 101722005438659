import React from 'react';
import PropTypes from 'prop-types';

// Own components
import { SubCategory } from '../containers/SubCategory';
import Layout from '../components/Layout/en';

// Assets & Misc
import { getQueryParam } from '../utils/getters';
import { navigateTo } from '../utils/navigation';
import { productsFamily } from '../constants/products';

const SubCategoryPage = () => {
  const familyId = getQueryParam('family');
  const subCategoryId = getQueryParam('subCategory');
  const family = productsFamily[familyId];
  let subCategory = null;
  if (family) {
    subCategory = (family.subcategories || []).find(({ id }) => id === subCategoryId);
  }

  if (!subCategory) {
    navigateTo('/404');
    return null;
  }

  return (
    <Layout>
      <SubCategory familyId={familyId} {...subCategory} />
    </Layout>
  );
};

SubCategoryPage.propTypes  = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired
};

export default SubCategoryPage;