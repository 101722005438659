// The product id must be unique in the array
export const productsFamily = {
  adhesivesAndIndustrialSealants: {
    categories: [
      {
        id: 'instantAdhesives',
        title: 'instantAdhesives',
        description: 'instantAdhesivesDescription',
        image: '/images/products/adhesivesCover.jpeg'
      },
      {
        id: 'anaerobicAdhesives',
        title: 'anaerobicAdhesives',
        description: 'anaerobicAdhesivesDescription',
        image: '/images/products/adhesivos-anaerobicos-category.jpeg'
      },
      {
        id: 'structuralAdhesives',
        title: 'structuralAdhesives',
        description: 'structuralAdhesivesDescription',
        image: '/images/products/structuralAdhesives-cover.jpeg'
      },
      {
        id: 'specialProducts',
        title: 'specialProducts',
        description: 'specialProductsDescription',
        image: '/images/products/specialProducts-cover.jpeg'
      },
    ],
    subcategories: [
      {
        id: 'traditionalAdhesives',
        category: 'instantAdhesives',
        name: 'traditionalAdhesives',
        coverImage: '/images/products/tech-bond-sq-14/tech-bond-sd14.jpeg',
        path: 'subCategory?family=adhesivesAndIndustrialSealants&subCategory=traditionalAdhesives',
        description: 'traditionalAdhesivesDescription',
        image: '/images/products/tech-bond-sq-14/tech-bond-sd14-detail.jpeg',
      },
      {
        id: 'reinforcedAdhesives',
        category: 'instantAdhesives',
        name: 'reinforcedAdhesives',
        coverImage: '/images/products/tech-hyper-bond-40511/tech-hyper-bond-40511.jpeg',
        path: 'subCategory?family=adhesivesAndIndustrialSealants&subCategory=reinforcedAdhesives',
        description: 'reinforcedAdhesivesDescription',
        image: '/images/services/adhesivos-instantaneo-media.jpeg',
      },
      {
        id: 'threadLockers',
        category: 'anaerobicAdhesives',
        name: 'threadLockers',
        coverImage: '/images/products/trabadores-de-roscas.jpeg',
        path: 'subCategory?family=adhesivesAndIndustrialSealants&subCategory=threadLockers',
        description: 'threadLockersDescription',
        image: '/images/products/trabadores-de-roscas-subcategory.jpeg',
      },
      {
        id: 'bushingBearingRetention',
        category: 'anaerobicAdhesives',
        name: 'bushingBearingRetention',
        coverImage: '/images/products/retenedores-cilindricos.jpeg',
        path: 'subCategory?family=adhesivesAndIndustrialSealants&subCategory=bushingBearingRetention',
        description: 'bushingBearingRetentionDescription',
        image: '/images/products/retenedores-cilindricos-subcategory.jpeg',
      },
      {
        id: 'pipeSealants',
        category: 'anaerobicAdhesives',
        name: 'pipeSealants',
        coverImage: '/images/products/selladores-tuberias.jpeg',
        path: 'subCategory?family=adhesivesAndIndustrialSealants&subCategory=pipeSealants',
        description: 'pipeSealantsDescription',
        image: '/images/products/selladores-de-tuberias-subcategory.jpeg',
      },
      {
        id: 'gasketMakers',
        category: 'anaerobicAdhesives',
        name: 'gasketMakers',
        coverImage: '/images/products/formadores-de-empaques.jpeg',
        path: 'subCategory?family=adhesivesAndIndustrialSealants&subCategory=gasketMakers',
        description: 'gasketMakersDescription',
        image: '/images/products/formadores-de-empaques-subcategory.jpeg',
      },
    ],
    products: [
      {
        id: 'techBondSQ14',
        category: '',
        subcategory: 'traditionalAdhesives',
        name: 'TECH BOND SQ.14',
        coverImage: '/images/products/tech-bond-sq-14/tech-bond-sd14.jpeg',
        path: 'product?family=adhesivesAndIndustrialSealants&product=techBondSQ14',
        description: 'techBondSQ14Description',
        multimedia: [
          {
            id: 'media-1',
            type: 'image',
            url: '/images/products/tech-bond-sq-14/tech-bond-sq-14-1.jpeg',
          },
          {
            id: 'media-2',
            type: 'image',
            url: '/images/products/tech-bond-sq-14/tech-bond-sq-14-2.jpeg',
          },
          {
            id: 'media-3',
            type: 'image',
            url: '/images/products/tech-bond-sq-14/tech-bond-sq-14-3.jpeg',
          },
        ],
      },
      {
        id: 'techHyperBond40511',
        category: '',
        subcategory: 'reinforcedAdhesives',
        name: 'TECH HYPER BOND 405.11',
        coverImage: '/images/products/tech-hyper-bond-40511/tech-hyper-bond-40511.jpeg',
        path: 'product?family=adhesivesAndIndustrialSealants&product=techHyperBond40511',
        description: 'techHyperBond40511Description',
        multimedia: [
          {
            id: 'media-1',
            type: 'image',
            url: '/images/products/tech-hyper-bond-40511/tech-hyper-bond-40511-2.jpeg',
          },
          {
            id: 'media-2',
            type: 'image',
            url: '/images/products/tech-hyper-bond-40511/tech-hyper-bond-40511-1.jpeg',
          },
          {
            id: 'media-3',
            type: 'image',
            url: '/images/products/tech-hyper-bond-40511/tech-hyper-bond-40511-3.jpeg',
          },
          {
            id: 'media-4',
            type: 'video',
            url: 'https://www.youtube-nocookie.com/embed/AJh7RntLUtg',
          },
        ],
      },
      {
        id: 'techLocker58142',
        category: 'anaerobicAdhesives',
        subcategory: 'threadLockers',
        name: 'TECH LOCKER 581.42',
        coverImage: '/images/products/tech-locker-581-42/tech-locker-581-42-cover.jpeg',
        path: 'product?family=adhesivesAndIndustrialSealants&product=techLocker58142',
        description: 'techLocker58142Description',
        multimedia: [
          {
            id: 'media-1',
            type: 'image',
            url: '/images/products/tech-locker-581-42/tech-locker-581-42-1.jpeg',
          },
          {
            id: 'media-2',
            type: 'image',
            url: '/images/products/tech-locker-581-42/tech-locker-581-42-2.jpeg',
          },
          {
            id: 'media-3',
            type: 'image',
            url: '/images/products/tech-locker-581-42/tech-locker-581-42-3.jpeg',
          },
        ],
      },
      {
        id: 'techLocker64171',
        category: 'anaerobicAdhesives',
        subcategory: 'threadLockers',
        name: 'TECH LOCKER 641.71',
        coverImage: '/images/products/tech-locker-641-71/tech-locker-641-71-cover.jpeg',
        path: 'product?family=adhesivesAndIndustrialSealants&product=techLocker64171',
        description: 'techLocker64171Description',
        multimedia: [
          {
            id: 'media-1',
            type: 'image',
            url: '/images/products/tech-locker-641-71/tech-locker-641-71-1.jpeg',
          },
          {
            id: 'media-2',
            type: 'image',
            url: '/images/products/tech-locker-641-71/tech-locker-641-71-2.jpeg',
          },
          {
            id: 'media-3',
            type: 'image',
            url: '/images/products/tech-locker-641-71/tech-locker-641-71-3.jpeg',
          },
        ],
      },
      {
        id: 'techLocker68177',
        category: 'anaerobicAdhesives',
        subcategory: 'threadLockers',
        name: 'TECH LOCKER 681.77',
        coverImage: '/images/products/tech-locker-681-77/tech-locker-681-77-cover.jpeg',
        path: 'product?family=adhesivesAndIndustrialSealants&product=techLocker68177',
        description: 'techLocker68177Description',
        multimedia: [
          {
            id: 'media-1',
            type: 'image',
            url: '/images/products/tech-locker-681-77/tech-locker-681-77-1.jpeg',
          },
          {
            id: 'media-2',
            type: 'image',
            url: '/images/products/tech-locker-681-77/tech-locker-681-77-2.jpeg',
          },
          {
            id: 'media-3',
            type: 'image',
            url: '/images/products/tech-locker-681-77/tech-locker-681-77-3.jpeg',
          },
        ],
      },
      {
        id: 'techLocker52090',
        category: 'anaerobicAdhesives',
        subcategory: 'threadLockers',
        name: 'TECH LOCKER 520.90',
        coverImage: '/images/products/tech-locker-520-90/tech-locker-520-90-cover.jpeg',
        path: 'product?family=adhesivesAndIndustrialSealants&product=techLocker52090',
        description: 'techLocker52090Description',
        multimedia: [
          {
            id: 'media-1',
            type: 'image',
            url: '/images/products/tech-locker-520-90/tech-locker-520-90-1.jpeg',
          },
          {
            id: 'media-2',
            type: 'image',
            url: '/images/products/tech-locker-520-90/tech-locker-520-90-2.jpeg',
          },
          {
            id: 'media-3',
            type: 'image',
            url: '/images/products/tech-locker-520-90/tech-locker-520-90-3.jpeg',
          },
        ],
      },
      {
        id: 'techRetainer69080',
        category: 'anaerobicAdhesives',
        subcategory: 'bushingBearingRetention',
        name: 'TECH RETAINER 690.80',
        coverImage: '/images/products/tech-locker-690-80/tech-locker-690-80-cover.jpeg',
        path: 'product?family=adhesivesAndIndustrialSealants&product=techRetainer69080',
        description: 'techRetainer69080Description',
        multimedia: [
          {
            id: 'media-1',
            type: 'image',
            url: '/images/products/tech-locker-690-80/tech-locker-690-80-1.jpeg',
          },
          {
            id: 'media-2',
            type: 'image',
            url: '/images/products/tech-locker-690-80/tech-locker-690-80-2.jpeg',
          },
          {
            id: 'media-3',
            type: 'image',
            url: '/images/products/tech-locker-690-80/tech-locker-690-80-3.jpeg',
          },
        ],
      },
      // {
      //   id: 'techRetainer66860',
      //   category: 'anaerobicAdhesives',
      //   subcategory: 'bushingBearingRetention',
      //   name: 'TECH RETAINER 668.60',
      //   coverImage: 'http://via.placeholder.com/370x286',
      //   path: 'product?family=adhesivesAndIndustrialSealants&product=techRetainer66860',
      //   description: 'techRetainer66860Description',
      //   multimedia: [
      //     {
      //       id: 'media-1',
      //       type: 'image',
      //       url: 'http://via.placeholder.com/448x448',
      //     }
      //   ],
      // },
      {
        id: 'techSeal67867',
        category: 'anaerobicAdhesives',
        subcategory: 'pipeSealants',
        name: 'TECH SEAL 678.67',
        coverImage: '/images/products/tech-seal-678-67/tech-seal-678-67-cover.jpeg',
        path: 'product?family=adhesivesAndIndustrialSealants&product=techSeal67867',
        description: 'techSeal67867Description',
        multimedia: [
          {
            id: 'media-1',
            type: 'image',
            url: '/images/products/tech-seal-678-67/tech-seal-678-67-1.jpeg',
          },
          {
            id: 'media-2',
            type: 'image',
            url: '/images/products/tech-seal-678-67/tech-seal-678-67-2.jpeg',
          },
          {
            id: 'media-3',
            type: 'image',
            url: '/images/products/tech-seal-678-67/tech-seal-678-67-3.jpeg',
          },
        ],
      },
      // {
      //   id: 'techGasket500018',
      //   category: 'anaerobicAdhesives',
      //   subcategory: 'gasketMakers',
      //   name: 'TECH GASKET 5000.18',
      //   coverImage: 'http://via.placeholder.com/370x286',
      //   path: 'product?family=adhesivesAndIndustrialSealants&product=techGasket500018',
      //   description: 'techGasket500018Description',
      //   multimedia: [
      //     {
      //       id: 'media-1',
      //       type: 'image',
      //       url: 'http://via.placeholder.com/448x448',
      //     }
      //   ],
      // },
      {
        id: 'techGasket300015',
        category: 'anaerobicAdhesives',
        subcategory: 'gasketMakers',
        name: 'TECH GASKET 3000.15',
        coverImage: '/images/products/tech-gasket-3000-15/tech-gasket-3000-15-cover.jpeg',
        path: 'product?family=adhesivesAndIndustrialSealants&product=techGasket300015',
        description: 'techGasket300015Description',
        multimedia: [
          {
            id: 'media-1',
            type: 'image',
            url: '/images/products/tech-gasket-3000-15/tech-gasket-3000-15-1.jpeg',
          },
          {
            id: 'media-2',
            type: 'image',
            url: '/images/products/tech-gasket-3000-15/tech-gasket-3000-15-2.jpeg',
          },
          {
            id: 'media-3',
            type: 'image',
            url: '/images/products/tech-gasket-3000-15/tech-gasket-3000-15-3.jpeg',
          },
        ],
      },
      {
        id: 'techOmegaBond2000',
        category: 'structuralAdhesives',
        subcategory: '',
        name: 'TECH ΩMEGA-BOND 2000',
        coverImage: '/images/products/tech-omega-bond-2000/tech-omega-bond-2000-cover.jpeg',
        path: 'product?family=adhesivesAndIndustrialSealants&product=techOmegaBond2000',
        description: 'techOmegaBond2000Description',
        multimedia: [
          {
            id: 'media-1',
            type: 'image',
            url: '/images/products/tech-omega-bond-2000/tech-omega-bond-2000-3.jpeg',
          },
          {
            id: 'media-2',
            type: 'image',
            url: '/images/products/tech-omega-bond-2000/tech-omega-bond-2000-2.jpeg',
          },
          {
            id: 'media-3',
            type: 'image',
            url: '/images/products/tech-omega-bond-2000/tech-omega-bond-2000-1.jpeg',
          },
          {
            id: 'media-4',
            type: 'video',
            url: 'https://www.youtube-nocookie.com/embed/8t_AKYZkp44',
          },
        ],
      },
      {
        id: 'techOmegaBond3000',
        category: 'structuralAdhesives',
        subcategory: '',
        name: 'TECH ΩMEGA-BOND 3000',
        coverImage: '/images/products/tech-omega-bond-3000/tech-omega-bond-3000-cover.jpeg',
        path: 'product?family=adhesivesAndIndustrialSealants&product=techOmegaBond3000',
        description: 'techOmegaBond3000Description',
        multimedia: [
          {
            id: 'media-1',
            type: 'image',
            url: '/images/products/tech-omega-bond-3000/tech-omega-bond-3000-1.jpeg',
          },
          {
            id: 'media-2',
            type: 'image',
            url: '/images/products/tech-omega-bond-3000/tech-omega-bond-3000-2.jpeg',
          },
          {
            id: 'media-3',
            type: 'image',
            url: '/images/products/tech-omega-bond-3000/tech-omega-bond-3000-3.jpeg',
          },
        ],
      },
      {
        id: 'techBindenPox5Minutes',
        category: 'structuralAdhesives',
        subcategory: '',
        name: 'TECH BINDEN-POX / 5 Minuten',
        coverImage: '/images/products/tech-binden-pox-5/tech-binden-cover.jpeg',
        path: 'product?family=adhesivesAndIndustrialSealants&product=techBindenPox5Minutes',
        description: 'techBindenPox5MinutesDescription',
        multimedia: [
          {
            id: 'media-1',
            type: 'image',
            url: '/images/products/tech-binden-pox-5/tech-binden-pox-5-3.jpeg',
          },
          {
            id: 'media-2',
            type: 'image',
            url: '/images/products/tech-binden-pox-5/tech-binden-pox-5-2.jpeg',
          },
          {
            id: 'media-3',
            type: 'image',
            url: '/images/products/tech-binden-pox-5/tech-binden-pox-5-1.jpeg',
          },
          {
            id: 'media-4',
            type: 'video',
            url: 'https://www.youtube-nocookie.com/embed/hn24NJuJmFI',
          }
        ],
      },
      {
        id: 'techPolyBond101',
        category: 'structuralAdhesives',
        subcategory: '',
        name: 'TECH POLY-BOND 10:1',
        coverImage: '/images/products/tech-poly-bond-101/tech-poly-bond101-cover.jpeg',
        path: 'product?family=adhesivesAndIndustrialSealants&product=techPolyBond101',
        description: 'techPolyBond101Description',
        multimedia: [
          {
            id: 'media-1',
            type: 'image',
            url: '/images/products/tech-poly-bond-101/tech-poly-bond-101-1.jpeg',
          },
          {
            id: 'media-2',
            type: 'image',
            url: '/images/products/tech-poly-bond-101/tech-poly-bond-101-2.jpeg',
          },
          {
            id: 'media-3',
            type: 'image',
            url: '/images/products/tech-poly-bond-101/tech-poly-bond-101-3.jpeg',
          },
        ],
      },
      {
        id: 'techFlussigmetall',
        category: 'structuralAdhesives',
        subcategory: '',
        name: 'TECH FLÜSSIGMETALL',
        coverImage: '/images/products/tech-flussigmetall/tech-flussigmetall-cover.jpeg',
        path: 'product?family=adhesivesAndIndustrialSealants&product=techFlussigmetall',
        description: 'techFlussigmetallDescription',
        multimedia: [
          {
            id: 'media-1',
            type: 'image',
            url: '/images/products/tech-flussigmetall/tech-flussigmetall-1.jpeg',
          },
          {
            id: 'media-2',
            type: 'image',
            url: '/images/products/tech-flussigmetall/tech-flussigmetall-2.jpeg',
          },
          {
            id: 'media-3',
            type: 'image',
            url: '/images/products/tech-flussigmetall/tech-flussigmetall-3.jpeg',
          },
        ],
      },
      {
        id: 'techUltraBond2k',
        category: 'specialProducts',
        subcategory: '',
        name: 'TECH ULTRA BOND 2K',
        coverImage: '/images/products/tech-ultra-bond-2k/tech-ultra-bond-2k.jpeg',
        path: 'product?family=adhesivesAndIndustrialSealants&product=techUltraBond2k',
        description: 'techUltraBond2kDescription',
        multimedia: [
          {
            id: 'media-1',
            type: 'image',
            url: '/images/products/tech-ultra-bond-2k/tech-ultra-bond-2k-1.jpeg',
          },
          {
            id: 'media-2',
            type: 'image',
            url: '/images/products/tech-ultra-bond-2k/tech-ultra-bond-2k-2.jpeg',
          },
          {
            id: 'media-3',
            type: 'image',
            url: '/images/products/tech-ultra-bond-2k/tech-ultra-bond-2k-3.jpeg',
          },
        ],
      },
      {
        id: 'techXtremeBond',
        category: 'specialProducts',
        subcategory: '',
        name: 'TECH XTREME BOND',
        coverImage: '/images/products/tech-xtreme-bond/tech-extreme-bond.jpeg',
        path: 'product?family=adhesivesAndIndustrialSealants&product=techXtremeBond',
        description: 'techXtremeBondDescription',
        multimedia: [
          {
            id: 'media-1',
            type: 'image',
            url: '/images/products/tech-xtreme-bond/tech-xtreme-bond-1.jpeg',
          },
          {
            id: 'media-2',
            type: 'image',
            url: '/images/products/tech-xtreme-bond/tech-xtreme-bond-2.jpeg',
          },
          {
            id: 'media-3',
            type: 'image',
            url: '/images/products/tech-xtreme-bond/tech-xtreme-bond-3.jpeg',
          },
        ],
      },
      {
        id: 'klebMastert3000',
        category: 'specialProducts',
        subcategory: '',
        name: 'KLEB-MASTER T3000',
        coverImage: '/images/products/tech-kleb-master-t3000/kleb-master-t3000-cover.jpeg',
        path: 'product?family=adhesivesAndIndustrialSealants&product=klebMastert3000',
        description: 'klebMastert3000Description',
        multimedia: [
          {
            id: 'media-1',
            type: 'image',
            url: '/images/products/tech-kleb-master-t3000/tech-kleb-master-t3000-1.jpeg',
          },
          {
            id: 'media-2',
            type: 'image',
            url: '/images/products/tech-kleb-master-t3000/tech-kleb-master-t3000-2.jpeg',
          },
          {
            id: 'media-3',
            type: 'image',
            url: '/images/products/tech-kleb-master-t3000/tech-kleb-master-t3000-3.jpeg',
          },
        ],
      },
      {
        id: 'techMsDiamantklar',
        category: 'specialProducts',
        subcategory: '',
        name: 'TECH MS DIAMANTKLAR',
        coverImage: '/images/products/tech-ms-diamantklar/tech-ms-diamantklar-cover.jpeg',
        path: 'product?family=adhesivesAndIndustrialSealants&product=techMsDiamantklar',
        description: 'techMsDiamantklarDescription',
        multimedia: [
          {
            id: 'media-1',
            type: 'image',
            url: '/images/products/tech-ms-diamantklar/tech-ms-diamantklar-1.jpeg',
          },
          {
            id: 'media-2',
            type: 'image',
            url: '/images/products/tech-ms-diamantklar/tech-ms-diamantklar-2.jpeg',
          },
          {
            id: 'media-3',
            type: 'image',
            url: '/images/products/tech-ms-diamantklar/tech-ms-diamantklar-3.jpeg',
          },
        ],
      },
      {
        id: 'techSolarPatch',
        category: 'specialProducts',
        subcategory: '',
        name: 'TECH SOLAR PATCH',
        coverImage: '/images/products/tech-solar-patch/tech-solar-patch-cover.jpeg',
        path: 'product?family=adhesivesAndIndustrialSealants&product=techSolarPatch',
        description: 'techSolarPatchDescription',
        multimedia: [
          {
            id: 'media-1',
            type: 'image',
            url: '/images/products/tech-solar-patch/tech-solar-patch-1.jpeg',
          },
          {
            id: 'media-2',
            type: 'image',
            url: '/images/products/tech-solar-patch/tech-solar-patch-2.jpeg',
          },
          {
            id: 'media-3',
            type: 'image',
            url: '/images/products/tech-solar-patch/tech-solar-patch-3.jpeg',
          },
        ],
      },
      {
        id: 'techKeramikpaste',
        category: 'specialProducts',
        subcategory: '',
        name: 'TECH KERAMIKPASTE',
        coverImage: '/images/products/tech-keramikpaste/tech-keramikpaste-cover.jpeg',
        path: 'product?family=adhesivesAndIndustrialSealants&product=techKeramikpaste',
        description: 'techKeramikpasteDescription',
        multimedia: [
          {
            id: 'media-1',
            type: 'image',
            url: '/images/products/tech-keramikpaste/tech-keramikpaste-1.jpeg',
          },
          {
            id: 'media-2',
            type: 'image',
            url: '/images/products/tech-keramikpaste/tech-keramikpaste-2.jpeg',
          },
          {
            id: 'media-3',
            type: 'image',
            url: '/images/products/tech-keramikpaste/tech-keramikpaste-3.jpeg',
          },
        ],
      },
      {
        id: 'techSilRtvOxiGray',
        category: 'specialProducts',
        subcategory: '',
        name: 'TECH SIL RTV OXI-GRAY',
        coverImage: '/images/products/tech-sil-rtv-oxy-gray/tech-sil-rtv-oxy-gray-cover.jpeg',
        path: 'product?family=adhesivesAndIndustrialSealants&product=techSilRtvOxiGray',
        description: 'techSilRtvOxiGrayDescription',
        multimedia: [
          {
            id: 'media-1',
            type: 'image',
            url: '/images/products/tech-sil-rtv-oxy-gray/tech-sil-rtv-oxy-gray-1.jpeg',
          },
          {
            id: 'media-2',
            type: 'image',
            url: '/images/products/tech-sil-rtv-oxy-gray/tech-sil-rtv-oxy-gray-2.jpeg',
          },
          {
            id: 'media-3',
            type: 'image',
            url: '/images/products/tech-sil-rtv-oxy-gray/tech-sil-rtv-oxy-gray-3.jpeg',
          },
        ],
      },
      {
        id: 'marstonUniversalSealant',
        category: 'specialProducts',
        subcategory: '',
        name: 'MARSTON UNIVERSAL SEALANT',
        coverImage: '/images/products/marston-universal-sealant/marston-universal-sealant-cover.jpeg',
        path: 'product?family=adhesivesAndIndustrialSealants&product=marstonUniversalSealant',
        description: 'marstonUniversalSealantDescription',
        multimedia: [
          {
            id: 'media-1',
            type: 'image',
            url: '/images/products/marston-universal-sealant/marston-universal-sealant-1.jpeg',
          },
          {
            id: 'media-2',
            type: 'image',
            url: '/images/products/marston-universal-sealant/marston-universal-sealant-2.jpeg',
          },
          {
            id: 'media-3',
            type: 'image',
            url: '/images/products/marston-universal-sealant/marston-universal-sealant-3.jpeg',
          },
          {
            id: 'media-4',
            type: 'image',
            url: '/images/products/marston-universal-sealant/marston-universal-sealant-4.jpeg',
          },
        ],
      },
    ],
  },
  advancedPolymerCoatings: {
    categories: [],
    subcategories: [],
    products: [
      {
        id: 'techMetalR1000',
        category: '',
        subcategory: '',
        name: 'TECH METAL R - 1000',
        coverImage: '/images/products/tech-metal-r-1000/tech-metal-r-1000-cover.jpeg',
        path: 'product?family=advancedPolymerCoatings&product=techMetalR1000',
        description: 'techMetalR1000Description',
        multimedia: [
          {
            id: 'media-1',
            type: 'image',
            url: '/images/products/tech-metal-r-1000/tech-metal-r-1000-1.jpeg',
          },
          {
            id: 'media-2',
            type: 'image',
            url: '/images/products/tech-metal-r-1000/tech-metal-r-1000-2.jpeg',
          },
          {
            id: 'media-3',
            type: 'image',
            url: '/images/products/tech-metal-r-1000/tech-metal-r-1000-3.jpeg',
          },
        ],
      },
      {
        id: 'techSpeddMetalEe1010Paste',
        category: '',
        subcategory: '',
        name: 'TECH SPEED METAL R – 1010 PASTE',
        coverImage: '/images/products/tech-speed-metal-r1010-paste/tech-speed-metal-r1010-paste-cover.jpeg',
        path: 'product?family=advancedPolymerCoatings&product=techSpeddMetalEe1010Paste',
        description: 'techSpeddMetalEe1010PasteDescription',
        multimedia: [
          {
            id: 'media-1',
            type: 'image',
            url: '/images/products/tech-speed-metal-r1010-paste/tech-speed-metal-r1010-paste-1.jpeg',
          },
          {
            id: 'media-2',
            type: 'image',
            url: '/images/products/tech-speed-metal-r1010-paste/tech-speed-metal-r1010-paste-2.jpeg',
          },
          {
            id: 'media-3',
            type: 'image',
            url: '/images/products/tech-speed-metal-r1010-paste/tech-speed-metal-r1010-paste-3.jpeg',
          },
        ],
      },
      {
        id: 'techSpeedMetalEr1020Fluid',
        category: '',
        subcategory: '',
        name: 'TECH SPEED METAL R – 1020 FLUID',
        coverImage: '/images/products/tech-speed-metal-r1020-fluid/tech-speed-metal-r1020-fluid-cover.jpeg',
        path: 'product?family=advancedPolymerCoatings&product=techSpeedMetalEr1020Fluid',
        description: 'techSpeedMetalEr1020FluidDescription',
        multimedia: [
          {
            id: 'media-1',
            type: 'image',
            url: '/images/products/tech-speed-metal-r1020-fluid/tech-speed-metal-r1020-fluid-1.jpeg',
          },
          {
            id: 'media-2',
            type: 'image',
            url: '/images/products/tech-speed-metal-r1020-fluid/tech-speed-metal-r1020-fluid-2.jpeg',
          },
          {
            id: 'media-3',
            type: 'image',
            url: '/images/products/tech-speed-metal-r1020-fluid/tech-speed-metal-r1020-fluid-3.jpeg',
          },
        ],
      },
      {
        id: 'techCeramicShieldP10',
        category: '',
        subcategory: '',
        name: 'TECH CERAMIC SHIELD P – 10',
        coverImage: '/images/products/tech-ceramic-shield-p10/tech-ceramic-shield-p10-cover.jpeg',
        path: 'product?family=advancedPolymerCoatings&product=techCeramicShieldP10',
        description: 'techCeramicShieldP10Description',
        multimedia: [
          {
            id: 'media-1',
            type: 'image',
            url: '/images/products/tech-ceramic-shield-p10/tech-ceramic-shield-p10-2.jpeg',
          },
          {
            id: 'media-2',
            type: 'image',
            url: '/images/products/tech-ceramic-shield-p10/tech-ceramic-shield-p10-1.jpeg',
          },
          {
            id: 'media-3',
            type: 'image',
            url: '/images/products/tech-ceramic-shield-p10/tech-ceramic-shield-p10-3.jpeg',
          },
        ],
      },
      {
        id: 'techCeramicShieldP20',
        category: '',
        subcategory: '',
        name: 'TECH CERAMIC SHIELD P – 20',
        coverImage: '/images/products/tech-ceramic-shield-p20/tech-ceramic-shield-p20-cover.jpeg',
        path: 'product?family=advancedPolymerCoatings&product=techCeramicShieldP20',
        description: 'techCeramicShieldP20Description',
        multimedia: [
          {
            id: 'media-1',
            type: 'image',
            url: '/images/products/tech-ceramic-shield-p20/tech-ceramic-shield-p20-1.jpeg',
          },
          {
            id: 'media-2',
            type: 'image',
            url: '/images/products/tech-ceramic-shield-p20/tech-ceramic-shield-p20-2.jpeg',
          },
          {
            id: 'media-3',
            type: 'image',
            url: '/images/products/tech-ceramic-shield-p20/tech-ceramic-shield-p20-3.jpeg',
          },
        ],
      },
      {
        id: 'techCeramicShieldP30',
        category: '',
        subcategory: '',
        name: 'TECH CERAMIC SHIELD P - 30',
        coverImage: '/images/products/tech-ceramic-shield-p30/tech-ceramic-shield-p30-cover.jpeg',
        path: 'product?family=advancedPolymerCoatings&product=techCeramicShieldP30',
        description: 'techCeramicShieldP30Description',
        multimedia: [
          {
            id: 'media-1',
            type: 'image',
            url: '/images/products/tech-ceramic-shield-p30/tech-ceramic-shield-p30-1.jpeg',
          },
          {
            id: 'media-2',
            type: 'image',
            url: '/images/products/tech-ceramic-shield-p30/tech-ceramic-shield-p30-2.jpeg',
          },
          {
            id: 'media-3',
            type: 'image',
            url: '/images/products/tech-ceramic-shield-p30/tech-ceramic-shield-p30-3.jpeg',
          },
        ],
      },
      {
        id: 'techXtremePuFlex',
        category: '',
        subcategory: '',
        name: 'TECH XTREME PU FLEX',
        coverImage: '/images/products/tech-xtreme-pu-flex/tech-xtreme-pu-flex-cover.jpeg',
        path: 'product?family=advancedPolymerCoatings&product=techXtremePuFlex',
        description: 'techXtremePuFlexDescription',
        multimedia: [
          {
            id: 'media-1',
            type: 'image',
            url: '/images/products/tech-xtreme-pu-flex/tech-xtreme-pu-flex-1.jpeg',
          },
          {
            id: 'media-2',
            type: 'image',
            url: '/images/products/tech-xtreme-pu-flex/tech-xtreme-pu-flex-2.jpeg',
          },
          {
            id: 'media-3',
            type: 'image',
            url: '/images/products/tech-xtreme-pu-flex/tech-xtreme-pu-flex-3.jpeg',
          },
        ],
      },
      {
        id: 'techArmorCladCrWr',
        category: '',
        subcategory: '',
        name: 'TECH ARMOR CLAD CR+WR',
        coverImage: '/images/products/tech-armor-clad-cr-wr/tech-armor-clad-cr-wr-cover.jpeg',
        path: 'product?family=advancedPolymerCoatings&product=techArmorCladCrWr',
        description: 'techArmorCladCrWrDescription',
        multimedia: [
          {
            id: 'media-1',
            type: 'image',
            url: '/images/products/tech-armor-clad-cr-wr/tech-armor-clad-cr-wr-1.jpeg',
          },
          {
            id: 'media-2',
            type: 'image',
            url: '/images/products/tech-armor-clad-cr-wr/tech-armor-clad-cr-wr-2.jpeg',
          },
          {
            id: 'media-3',
            type: 'image',
            url: '/images/products/tech-armor-clad-cr-wr/tech-armor-clad-cr-wr-3.jpeg',
          },
        ],
      },
    ],
  },
  elastomericThermalInsulations: {
    categories: [],
    subcategories: [],
    products: [
      {
        id: 'isopipeTc',
        category: '',
        subcategory: '',
        name: 'ISOPIPE TC',
        coverImage: '/images/products/isopipe-tc/isopipe-solar-tc-cover.jpeg',
        path: 'product?family=elastomericThermalInsulations&product=isopipeTc',
        description: 'isopipeTcDescription',
        extraDescription: 'isopipeTcExtraDescription',
        multimedia: [
          {
            id: 'media-1',
            type: 'image',
            url: '/images/products/isopipe-tc/isopipe-solar-tc-1.jpeg',
          },
          {
            id: 'media-2',
            type: 'image',
            url: '/images/products/isopipe-tc/isopipe-solar-tc-2.jpeg',
          },
          {
            id: 'media-3',
            type: 'image',
            url: '/images/products/isopipe-tc/isopipe-uv-plus-tc-1.jpeg',
          },
          {
            id: 'media-4',
            type: 'image',
            url: '/images/products/isopipe-tc/isopipe-uv-plus-tc-2.jpeg',
          },
          {
            id: 'media-5',
            type: 'image',
            url: '/images/products/isopipe-tc/isopipe-heavy-duty-tc-1.jpeg',
          },
          {
            id: 'media-6',
            type: 'image',
            url: '/images/products/isopipe-tc/isopipe-heavy-duty-tc-2.jpeg',
          },
        ],
      },
      {
        id: 'isopipeHt',
        category: '',
        subcategory: '',
        name: 'ISOPIPE HT',
        coverImage: '/images/products/isopipe-ht/isopipe-ht-cover.jpeg',
        path: 'product?family=elastomericThermalInsulations&product=isopipeHt',
        description: 'isopipeHtDescription',
        extraDescription: 'isopipeHtExtraDescription',
        multimedia: [
          {
            id: 'media-1',
            type: 'image',
            url: '/images/products/isopipe-ht/isopipe-ht.jpeg',
          }
        ],
      },
      {
        id: 'isosound',
        category: '',
        subcategory: '',
        name: 'ISOSOUND',
        coverImage: '/images/products/isosound/isosound-cover.jpeg',
        path: 'product?family=elastomericThermalInsulations&product=isosound',
        description: 'isosoundDescription',
        extraDescription: '',
        multimedia: [
          {
            id: 'media-1',
            type: 'image',
            url: '/images/products/isosound/isosound.jpeg',
          }
        ],
      },
    ],
  },
  plasmaSurfaceTreatment: {
    categories: [],
    subcategories: [],
    products: [
      {
        id: 'piezobrushPz3',
        category: '',
        subcategory: '',
        name: 'PIEZOBRUSH® PZ3',
        coverImage: '/images/products/piezobrush-pz3/piezobrush-pz3-cover.jpeg',
        path: 'product?family=plasmaSurfaceTreatment&product=piezobrushPz3',
        description: 'piezobrushPz3Description',
        extraDescription: 'piezobrushPz3ExtraDescription',
        multimedia: [
          {
            id: 'media-1',
            type: 'image',
            url: '/images/products/piezobrush-pz3/piezobrush-pz3-1.jpeg',
          },
          {
            id: 'media-2',
            type: 'image',
            url: '/images/products/piezobrush-pz3/piezobrush-pz3-2.jpeg',
          },
          {
            id: 'media-3',
            type: 'image',
            url: '/images/products/piezobrush-pz3/piezobrush-pz3-3.jpeg',
          },
          {
            id: 'media-4',
            type: 'video',
            url: 'https://www.youtube-nocookie.com/embed/SiZvQNLlYTk',
          },
        ],
      },
      {
        id: 'plasmatool',
        category: '',
        subcategory: '',
        name: 'PLASMATOOL',
        coverImage: '/images/products/plasmatool/plasmatool-cover.jpeg',
        path: 'product?family=plasmaSurfaceTreatment&product=plasmatool',
        description: 'plasmatoolDescription',
        extraDescription: 'plasmatoolExtraDescription',
        multimedia: [
          {
            id: 'media-1',
            type: 'image',
            url: '/images/products/plasmatool/plasmatool-1.jpeg',
          },
          {
            id: 'media-2',
            type: 'image',
            url: '/images/products/plasmatool/plasmatool-2.jpeg',
          },
          {
            id: 'media-4',
            type: 'video',
            url: 'https://www.youtube-nocookie.com/embed/JUzklsDLJaw',
          },
        ],
      },
      {
        id: 'plasmabrushPb3',
        category: '',
        subcategory: '',
        name: 'PLASMABRUSH® PB3',
        coverImage: '/images/products/plasmabrush-pb3/plasmabrush-pb3-cover.jpeg',
        path: 'product?family=plasmaSurfaceTreatment&product=plasmabrushPb3',
        description: 'plasmabrushPb3Description',
        extraDescription: 'plasmabrushPb3ExtraDescription',
        multimedia: [
          {
            id: 'media-1',
            type: 'image',
            url: '/images/products/plasmabrush-pb3/plasmabrush-pb3-1.jpeg',
          },
          {
            id: 'media-2',
            type: 'image',
            url: '/images/products/plasmabrush-pb3/plasmabrush-pb3-2.jpeg',
          },
          {
            id: 'media-3',
            type: 'image',
            url: '/images/products/plasmabrush-pb3/plasmabrush-pb3-3.jpeg',
          },
          {
            id: 'media-4',
            type: 'video',
            url: 'https://www.youtube-nocookie.com/embed/XORKXNWDyQk',
          },
        ],
      },
      {
        id: 'plasmacellP300',
        category: '',
        subcategory: '',
        name: 'PLASMACELL P300',
        coverImage: '/images/products/plasmacell-300/plasmacell-300-cover.jpeg',
        path: 'product?family=plasmaSurfaceTreatment&product=plasmacellP300',
        description: 'plasmacellP300Description',
        extraDescription: 'plasmacellP300ExtraDescription',
        multimedia: [
          {
            id: 'media-1',
            type: 'image',
            url: '/images/products/plasmacell-300/plasmacell-300-1.jpeg',
          },
          {
            id: 'media-2',
            type: 'image',
            url: '/images/products/plasmacell-300/plasmacell-300-2.jpeg',
          },
          {
            id: 'media-3',
            type: 'image',
            url: '/images/products/plasmacell-300/plasmacell-300-3.jpeg',
          },
          {
            id: 'media-4',
            type: 'video',
            url: 'https://www.youtube-nocookie.com/embed/o9-_BQST9GQ',
          },
        ],
      },
    ],
  }
};