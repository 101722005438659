import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

// Assets & Misc
import { useLanguages } from '../../hooks';
import { CustomLink } from '../../utils/navigation';

export const Product = ({ id, name, category, coverImage, path: productPath }) => {
  const { path } = useLanguages();
  const translatedName = name.split(' ').length > 1 ? name : <FormattedMessage id={name} />;

  return (
    <div className={`col-lg-3 col-md-3 col-sm-6 col-xs-12 img mbot30 isotopeSelector ${category}`} style={{ minHeight: 280, maxHeight: 400 }}>
      <div className="grid">
        <div className="image-zoom-on-hover">
          <div className="gal-item">
            <CustomLink className="black-hover" to={`${path}${productPath}`} state={{ id }}>
              <img className="img-full img-responsive lazyload" data-src={coverImage} alt={id} />
              <div className="tour-layer delay-1" />
              <div className="vertical-align">
                <div className="border">
                  <h5>{translatedName}</h5>
                </div>
                <div className="view-all hvr-bounce-to-right slide_learn_btn view_project_btn"><FormattedMessage id="viewProduct"/></div>
              </div>
            </CustomLink>
          </div>
        </div>
      </div>
      <div className="marbtm30" />
      <h4>{translatedName}</h4>
    </div>
  );
};

Product.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  category: PropTypes.string,
  coverImage: PropTypes.string,
};

export const ProductsList = ({ products = [], style }) => (
    <section className="portfoio-section" style={style}>
      <div className="container">
        <div className="portfolio-section port-col project_classic portfolio-4">
          <div className="isotopeContainer">
            {products.map(product => (<Product key={product.id} {...product} />))}
          </div>
        </div>
      </div>
    </section>
  );

ProductsList.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    shortDescription: PropTypes.string,
    category: PropTypes.string,
    coverImage: PropTypes.string,
  })),
  style: PropTypes.objectOf(PropTypes.any),
};
