import React, { useMemo } from 'react';
import { useIntl, FormattedHTMLMessage, FormattedMessage } from 'react-intl';

// Own component
import SEO from '../../components/seo';
import { ProductsList } from '../../components/ProductsList';
import SectionCover from '../../components/SectionCover/sectionCover';

// Assets & Misc
import { productsFamily } from '../../constants/products';

export const SubCategory = ({ familyId, id, name, description, image, category }) => {
  const { formatMessage } = useIntl();
  const family = productsFamily[familyId];
  const products = useMemo(() => {
    if (!family) return [];

    return family.products.filter(({ subcategory }) => subcategory === id);
  }, [familyId, id]);
  const categoryData = useMemo(() => {
    if (!family) return null;

    return family.categories.find(cat => cat.id === category);
  }, []);

  if (!categoryData) return null;

  return (
    <>
      <SEO title={formatMessage({ id: name })} />
      <SectionCover title={formatMessage({ id: categoryData.title })} src={categoryData.image} />
      <section className="pad45-45-top-bottom">
        <div className="container">
          {/* Description */}
          <div className="row">
            <div className="col-md-12 text-justify">
              <FormattedHTMLMessage id={categoryData.description} />
              <div className="service-right-desc">
                <span className="image_hover ">
                  <img data-src={image} className="img-responsive zoom_img_effect lazyload" alt={name} />
                </span>
              </div>
              <FormattedMessage id={name} tagName="h3"/>
              <p className="text-justify">
                <FormattedHTMLMessage id={description} />
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* Products */}
      <ProductsList products={products} />
    </>
  );
};
